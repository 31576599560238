<template lang="pug">
include ../pug/svg
section#media
	MediaGrid(:modal="false" :mediaType="null" v-model:selected="selectedMedia" @editMedia="editMedia" @deleteMedia="deleteMedia" @addMedia="addMedia")
	div.modal.center(:class="{active:showModal==='add'}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Add {{terminology('media','media','plural')}}
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		div.body
			//-div.upload
				strong Media Assets
				span (JPG maxsize 2MB)
				+svg(svg-filename="iconUpload" aria-hidden="true" alt="Upload icon")
			DropZone(
				ref="dropzone"
				:filesizeBase="dzOpts.filesizeBase"
				:paramName="dzOpts.paramName"
				:url="dzOpts.url"
				:withCredentials="dzOpts.withCredentials"
				:uploadOnDrop="dzOpts.uploadOnDrop"
				:multipleUpload="dzOpts.multipleUpload"
				:parallelUpload="dzOpts.parallelUpload"
				:maxFiles="dzOpts.maxFiles"
				:maxFileSize="maxFileSize"
				:acceptedFiles="acceptedFileTypes"
				@addedFile="onFileAdd"
				@removedFile="onFileRemove"
				@sending="onSend"
				@errorAdd="onErrorAdd"
				@errorUpload="onErrorUpload"
				@uploaded="onUploaded"
			)
			ul.dz-errors(v-if="dzErrors.length")
				li(v-for="(error, index) in dzErrors")
					span {{error}}
					button.but(type="button" @click="dzErrors.splice(index,1)") 
						span Dismiss
						+svg(svg-filename="iconClose")
		div.foot
			span.req {{acceptedFileString}}
			button.but.pri(type="button" @click="doUpload" :disabled="!addedFiles.length") Upload
			button.but.sec(type="button" @click="removeFiles" :disabled="!addedFiles.length") Clear
			button.but.sec(type="button" @click="removeFiles(), toggleModal(false)") Close
	div.modal.center(:class="{active:showModal&&showModal!=='add'}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Edit {{terminology('media','media','singular')}}
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		div.body
			Form(ref="formEdit" :validation-schema="editSchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="saveMedia")
				transition(name="modalform")
					fieldset.fgroup(v-if="showModal")
						div.field.c2
							Field(name="name" type="text" placeholder="Unsupplied" autocomplete="off" :value="showModal.name" :validateOnInput="false")
							label {{terminology('media','media','singular')}} Name
							transition(name="fade")
								ErrorMessage.err(name="name" as="span")
						div.field.c2
							Field(name="credit" type="text" placeholder="Unsupplied" autocomplete="off" :value="showModal.credit" :validateOnInput="false")
							label Photo Credit
							transition(name="fade")
								ErrorMessage.err(name="credit" as="span")
						div.field.c2(v-if="showModal.mediaType==='image'")
							Field(name="seoAlt" type="text" placeholder="Unsupplied" autocomplete="off" :value="showModal.seoAlt" :validateOnInput="false")
							label Alt Text
							transition(name="fade")
								ErrorMessage.err(name="seoAlt" as="span")
						div.field.c2(v-if="showModal.mediaType==='image'")
							Field(name="seoDescription" type="text" placeholder="Unsupplied" autocomplete="off" :value="showModal.seoDescription" :validateOnInput="false")
							label Image Caption
							transition(name="fade")
								ErrorMessage.err(name="seoDescription" as="span")
				div.foot
					span.req Required
					button.but.pri(type="submit" :disabled="!meta.valid||!meta.dirty") Save
					button.but.sec(type="reset" :disabled="!meta.dirty") Revert
	div.modal.confirm.center(:class="{active:deleteModal!==false}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Delete {{terminology('media','media','singular')}}
			button.but(type="button" @click="cancelDelete") Close
				+svg(svg-filename="iconClose")
		div.body
			p Please ensure that the {{terminology('media','media','singular')}} is not in use before deletion.
		div.foot
			button.but.pri(@click="confirmDelete") Confirm
			button.but.sec(@click="cancelDelete") Cancel
</template>

<script>
import MediaService from '../services/MediaService';
import Search from '../components/Search';
import Filter from '../components/Filter';
import MediaGrid from '../components/MediaGrid';
import dayjs from 'dayjs';
import { DropZone } from 'dropzone-vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default {
	name: 'ManageMedia',
	components: {
		Form,
		Field,
		ErrorMessage,
		Search,
		Filter,
		DropZone,
		MediaGrid,
	},
	data() {
		const apiBaseUrl = (process.env.VUE_APP_VUE_ENV === 'development') ? process.env.VUE_APP_API_BASE_URL : window.location.origin + '/api';
		const editSchema = Yup.object().shape({
//			id: Yup.string().label('Media Name'),
//			name: Yup.string().label('Media Name'),
//			seoAlt: Yup.string().label('Alt Text'),
//			seoDescription: Yup.string().label('Image Caption'),
//			credit: Yup.string().label('Photo Credit'),
		});
		return {
			editSchema,
			selectedMedia: null,
			search: null,
			typeFilter: null,
			groupFilter: null,
			showModal: false,
			deleteModal: false,
			previewIndex: 0,
			dzOpts: {
				url: apiBaseUrl + '/media/upload',
				withCredentials: true, // pass cookies
				paramName: 'files', // match to multerUpload
				uploadOnDrop: false,
				multipleUpload: true,
				filesizeBase: 1000, // bytes
				maxFiles: 6,
				parallelUpload: 6,
			},
			dzErrors: [],
			addedFiles: [],
			uploadedFiles: [],
		}
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('media','media','plural'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},			
		});
		
//		this.refreshMedia();
	},
	computed: {
		/*medias() {
			// copy store to local (allows list sorting)
			return [...this.$store.state.media.medias];
		},
		filteredMedias() {
			console.log('sortOption:', this.sortOption.value);
			// type filter
			let f1 = (this.typeFilter?.value) ? this.medias.filter(m => m.mediaType === this.typeFilter.value) : this.medias;
			
			// group filter
			let f2 = (this.groupFilter?.value) ? f1.filter(m => m.mediaGroup?.id === this.groupFilter.value) : f1;
			// sort order
			if (this.sortOption.value === 'oldest') {
				f2 = f2.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
			} else if (this.sortOption.value === 'newest') {				
				f2 = f2.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);
			} else if (this.sortOption.value === 'alpha') {
				f2 = f2.sort((a, b) => (a.filename > b.filename) ? 1 : -1);
			}
			
			// serch term filter
			if (this.search) {
				const term = this.search.toLowerCase();
				
				return f2.filter(m => {
					// check term against filename, name, alt and description
					if (m.name && m.name.toLowerCase().includes(term)
						|| m.filename.toLowerCase().includes(term)
						|| (m.seoAlt && m.seoAlt.toLowerCase().includes(term))
						|| (m.seoDescription && m.seoDescription.toLowerCase().includes(term))) {
						return true;
					}
					
					return false;
				});
				
			} else {
				return f2;
			}
		},
		typeFilters() {
			// get all media types
			const mediaTypes = this.medias.map(m => {
				return m.mediaType;
			});
			
			// remove duplicates and map value/option pairs to media type
			return [...new Set(mediaTypes)].map(t => {
				return { value: t, option: t };
			});
		},
		groupFilters() {
			// map value/option pairs to group data
			return this.$store.state.media.mediaGroups.map(g => {
				return { value: g.id, option: g.name };
			});
		},
//		previewMedia() {
//			return this.filteredMedias[this.previewIndex];
//		},*/
		maxFileSizes() {
			return this.$store.getters['gui/maxFileSizes'];
		},
		maxFileSize() {
			// get largest accepted filesize (default dz validation)
			const sizes = this.maxFileSizes;
			
			const keysSorted = Object.keys(sizes).sort((a,b) => {
				return sizes[b].max - sizes[a].max;
			});
			
			return sizes[keysSorted[0]].max;
		},
		acceptedFileTypes() {
			const exts = [];
			
			for(let key in this.maxFileSizes) {
				exts.push(this.maxFileSizes[key].exts);
			}
			
			return exts.flat();
		},
		acceptedFileString() {
			let str = 'Accepts:';
			
			for(let key in this.maxFileSizes) {
				const max = this.formatBytes(this.maxFileSizes[key].max, 1);
				
				for(let ext of this.maxFileSizes[key].exts) {
					str += ` ${ext} (${max}),`;
				}
			}
			
			return str.replace(/,*$/, '');
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		/*onLoad(media) {
			this.$store.dispatch('media/setMediaLoaded', {
				mediaId: media.id,
			});
		},*/
		deleteMedia(media) {
			// confirmation required
			this.deleteModal = media.id;
			this.showTint();
		},
		async confirmDelete() {
			try {
				// delete media
				const {status} = await MediaService.deleteMedia(this.deleteModal);
				
				if (status === 200) {
					// remove from store
					this.$store.dispatch('media/unsetMedia', {
						mediaId: this.deleteModal,
					});
					
					this.cancelDelete(); // close modal
				}
				
			} catch(err) {
				console.log('confirmDelete():err', err);
			}
		},
		cancelDelete() {
			this.deleteModal = false;
			this.hideTint();
		},
		editMedia(media) {
			this.toggleModal(media);
		},
//		async saveMedia(values, actions) {
		async saveMedia(values) {
			try {
				// update media
				values.id = this.showModal.id;
				const {data} = await MediaService.updateMedia(values);

				// update store
				this.$store.dispatch('media/updateMedia', {
					mediaId: data.media.id,
					name: data.media.name,
					seoAlt: data.media.seoAlt,
					seoDescription: data.media.seoDescription,
					credit: data.media.credit,
				});

				// close modal
				this.toggleModal(false);

			} catch(err) {
//				actions.setFieldError('email', err.response.data.message);
			}
		},
		doUpload() {
			// perform manual upload
			this.$refs.dropzone.processQueue();
		},
		onFileAdd(item) {
			// custom size validation by file type
			const type = item.file['type'].split('/')[0];
			const maxSize = this.maxFileSizes[type].max;
			
			if (item.file.size > maxSize) {
				this.$refs.dropzone.$emit('error-add', { files: [item.file], error: 'MAX_FILE_SIZE' });
				// note: removal now takes place in onErrorAdd to prevent error
				return;
			}
			
			let data = {
				id: item.id,
				name: item.file.name,
				width: null,
				height: null,
			};
			
			if (item.file['type'].split('/')[0] === 'image') {
				// get dimensions
				let img = new Image();
				
				img.onload = () => {
					data.width = img.width;
					data.height = img.height;
					
					this.addedFiles.push(data);
				}
				
				img.src = window.URL.createObjectURL(item.file);
				
			} else {
				this.addedFiles.push(data);
			}
		},
		onErrorAdd({files, error}) {
			let message = files[0].name + ' - ';
			
			if (error === 'MAX_FILE_SIZE') {
				const key = files[0]['type'].split('/')[0];
				const max = this.formatBytes(this.maxFileSizes[key].max, 1);
				
				message += 'exceeds maximum filesize ('+max+')';
				
				// ensure removal following custom custom size validation in onFileAdd()
				this.$refs.dropzone.removeFile(files[0].id);
				
			} else if (error === 'INVALID_TYPE') {
				message += 'is not a valid file type';
				
			} else if (error === 'MAX_FILE') {
				message += 'exceeds maximum file uploads';		   
			}
			
			this.dzErrors.push(message);
		},
		onFileRemove(item) {
			this.addedFiles = this.addedFiles.filter(f => f.id !== item.id);
			this.uploadedFiles = this.uploadedFiles.filter(f => f.id !== item.id);
		},
		removeFiles() {
			for (let f of [...this.addedFiles, ...this.uploadedFiles]) {
				this.$refs.dropzone.removeFile(f.id);
			}
			this.dzErrors = [];
		},
		onSend(files, xhr, formData) {
			// pass image dimensions via form data
			let fileData = [];
			for (let i in files) {
				fileData.push(this.addedFiles.find(f => f.name === files[i].name));
			}

			formData.append('fileData', JSON.stringify(fileData));
		},
		onUploaded(items) {
			for (let i of items) {
				// move item from addedFiles to uploadedFiles
				this.uploadedFiles.push(this.addedFiles.find(f => f.name === i.name));
				this.addedFiles = this.addedFiles.filter(f => f.name !== i.name);
			}
			
			this.refreshMedia();
		},
		onErrorUpload({ids, errorType}) {
			console.log('onErrorUpload:ids:',ids, errorType);
			/*
			this.dzErrors.push({
				file: files[0].name,
				message: message,
			});*/
		},
		toggleModal(modal) {
			this.showModal = modal;

			if (this.showModal) {
				this.showTint();
			} else {
				this.hideTint();

				if (modal === 'add') {
					setTimeout(function() {
						this.removeFiles();
					}.bind(this), 600);
				}
			}
		},
		addMedia() {
			this.toggleModal('add');
		},
		showTint() {
			this.$store.dispatch('gui/setTintState', true);
		},
		hideTint() {
			this.$store.dispatch('gui/setTintState', false);
		},
		async refreshMedia() {
			// load media from db
			await this.$store.dispatch('media/dbMedias');
		},
		formatBytes(bytes, decimals = 1) {
			if (bytes === 0) return '0 Bytes';

//			const k = 1024;
			const k = 1000;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		},
		formatDate(datetime) {
			return dayjs(datetime).format('DD MMM YYYY');
		},
	},
}
</script>

<style lang="scss">
#th {
	&.menu #media {
		.grid {
			@media (min-width: 100px) and (max-width: 719px) {
				max-width: 180px;
			}
			@media (min-width: 720px) and (max-width: 899px) {
				max-width: 360px;
			}
			@media (min-width: 900px) and (max-width: 1099px) {
				max-width: 540px;
			}
			@media (min-width: 1100px) and (max-width: 1279px) {
				max-width: 720px;
			}
			@media (min-width: 1280px) {
				max-width: 900px;
			}
		}
	}
	&:not(.menu) #media {
		.grid {
			@media (max-width: 719px) {
				max-width: 180px;
			}
			@media (min-width: 720px) and (max-width: 899px) {
				max-width: 360px;
			}
			@media (min-width: 900px) and (max-width: 919px) {
				max-width: 540px;
			}
			@media (min-width: 920px) and (max-width: 979px) {
				max-width: 180px;
			}
			@media (min-width: 980px) and (max-width: 1179px) {
				max-width: 360px;
			}
			@media (min-width: 1180px) and (max-width: 1359px) {
				max-width: 540px;
			}
			@media (min-width: 1360px) {
				max-width: 720px;
			}
		}
	}
}
</style>
